.pt-6 {
  padding-top: 6.5rem;
}
.pb-6 {
  padding-bottom: 6.5rem;
}

@media (min-width: 992px) {
  .pb-lg-6 {
    padding-bottom: 6.5rem !important;
  }
}

@media (min-width: 768px) {
  .pb-md-6 {
    padding-bottom: 6.5rem !important;
  }
  .pt-md-6 {
    padding-top: 6.5rem !important;
  }

}
