.btn.p0 {
  padding: 0;
  .inner-button {
    display: block;
    padding: 1rem 1.5rem;
  }
}

.btn-outline-blue:not([disabled]):not(.disabled):active,
.btn-outline-blue:not([disabled]):not(.disabled).active,
.show > .btn-outline-blue.dropdown-toggle {
  box-shadow: 0 0 0 0;
  background-color: #fff !important;
  border-color: $sky-blue !important;
}
.btn-outline-blue:not([disabled]):not(.disabled):active:focus,
.btn-outline-blue:not([disabled]):not(.disabled).active:focus,
.show > .btn-outline-blue.dropdown-toggle:focus {
  box-shadow: 0 0 0 0;
}

.btn {
  box-shadow: 0 0 0 0;
  padding: 0.75rem 1.5rem;
  font-size: 1rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  margin: 0;
  text-transform: none;
  color: #fff;
}

.btn-primary {
  background-color: $primary-color !important;
  color: #fff !important;

  &:hover,
  &:focus,
  &:active {
    box-shadow: 0 0 0 0;
    background-color: #ed795f;
  }
  &.blue {
    background-color: $off-blue !important;
    &:hover,
    &:focus,
    &:active {
      box-shadow: 0 0 0 0;
      background-color: $sky-blue !important;
    }
  }
}
.btn-outline-blue {
  border: 2px solid $off-blue !important;
  //	box-shadow: inset 0 0 0 2px $off-blue;
  background-color: transparent !important;
  color: $off-blue !important;
}

.btn[class*="btn-outline-"] {
  padding-top: 0.625rem;
  padding-bottom: 0.625rem;
}

.btn-med-width {
  width: 12rem;
}
@media (max-width: 575px) {
  .btn-med-width {
    width: auto;
    min-width: 8rem;
  }
}

.btn-sm-width {
  width: 8rem;
}

.btn-large {
  font-size: 1.125rem;
  padding: 1rem 1.5rem;
  line-height: 1.33333333333;
}

.btn-primary:not([disabled]):not(.disabled):active:focus,
.btn-primary:not([disabled]):not(.disabled).active:focus,
.show > .btn-primary.dropdown-toggle:focus,
.btn-primary:not([disabled]):not(.disabled):active,
.btn-primary:not([disabled]):not(.disabled).active,
.show > .btn-primary.dropdown-toggle {
  box-shadow: 0 0 0 0, 0 0 0 0;
  background-color: $sky-blue !important;
}

.btn-outline-blue:hover,
.btn-outline-blue:focus,
.btn-outline-blue:active,
.btn-outline-blue:active:focus,
.btn-outline-blue.active {
  border-color: $sky-blue !important;
  background-color: #fff !important;
  color: $sky-blue !important;
}

.btn:hover,
.btn:active,
.btn:focus {
  box-shadow: 0 0 0 0, 0 0 0 0;
  outline: 0;
}
