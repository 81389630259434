@import "styles/_colors.scss";
@import "styles/_functions.scss";
@import "styles/_navbar.scss";
@import "styles/_headings.scss";
@import "styles/_forms.scss";
@import "styles/_buttons.scss";
@import "styles/_images.scss";
@import "styles/_icons.scss";
@import "styles/_spacing.scss";
@import "styles/_text.scss";
@import "styles/_tabs.scss";
@import "styles/_symptoms.scss";
@import "styles/_sidenav.scss";
@import "styles/_modal.scss";
@import "styles/_cards.scss";
@import "styles/_doses.scss";
@import "styles/_footer.scss";
@import "styles/_tooltips.scss";
@import "styles/_flex.scss";
@import "styles/_lists.scss";
body {
  font-family: "Noto Sans", sans-serif;
  font-weight: 400;
  color: $dark-cyan;
  background-color: $pale-gray;
}
.secondary-content {
  margin-top: 4.5rem;
}
@media (max-width: 767.98px) {
  .secondary-content {
    margin-top: 3.5rem;
  }
}
.banner-bg {
  background: url(images/bg.jpg);
  background-size: cover;
  background-position: center center;
  position: relative;
  color: #fff;
  height: 42.5rem;
  padding-top: 5rem;
}
@media (max-width: 575px) {
.banner-bg {
    height: auto;
    padding-bottom: 5rem;
    padding-top: 7rem;
}
}
.dose-calc-banner {
  background-image: url(images/dose-calc.jpg);
  height: auto;
  padding: 6.5rem 0;
}
.ithermo-banner {
  background-image: url(images/ithermonitor.jpg);
  height: auto;
  padding: 6.5rem 0;
}
.about-banner {
  background-image: url(images/step3.jpg);
  height: auto;
  padding: 13.75rem 0;
}
@media (max-width: 767.98px) {
  .ithermo-banner {
    padding: 3.5rem 0;
    text-align: center;
  }
}
.ithermo-iphone-banner {
  background-image: url(images/ithermonitor-iphone-sm.png);
  height: auto;
  padding: 6.5rem 0;
  background-size: contain;
  background-repeat: no-repeat;
  background-color: $dark-cyan;
}
@media (max-width: 767.98px) {
  .ithermo-iphone-banner {
    height: 20rem;
  }
}
.footer {
  background-color: $dark-cyan;
  color: #fff;
  padding: 3rem 0;
}
.overlay {
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  background: rgba($black, 0.4);
}
@media (max-width: 575px) {
  .overlay {
    background: rgba($black, 0.75);
  }
}
.overlay.x-light {
  background: rgba($black, 0.05);
}
.wrapper.light {
  background-color: #fff;
}
@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

hr {
  margin-top: 2rem;
  border-top: 1px solid $light-gray;
  margin-bottom: 2rem;
}

@media (max-width: 768px) {
  .hidden-sm-down {
    display: none !important;
  }
}

@media (max-width: 575px) {
  .hidden-xs-down {
    display: none !important;
  }
}
