.lh3 {
line-height: 3;
}
.tagline {
	display: inline-block;
	font-size: 1.5rem;
	line-height: 2;
	font-family: 'Comfortaa';
}

.bold {
font-weight: 700;
}

.small, small {
    font-size: .875rem;
	line-height: get-line-height(14px, 24px);
}

.link {
	font-weight: 700;
	color: $primary-color;
}

a:hover {
	color: $sky-blue;
}
