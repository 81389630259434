.card .card-body .card-text {
    color: $dark-cyan;
    font-size: 1rem;
    font-weight: 400;
}

.card {
	background: $pale-gray;
    box-shadow: 1px 1px 2px 0 rgba($dark-cyan, 0.2);

	.card-body {
		padding: 1.5rem 1rem;
	}
}

@media (max-width: 767px) {
	.card {
width: 100%;
	}
}
