@media (min-width: 992px) {
  .tab-content {
    min-height: 25rem;
    justify-content: center;
    display: flex;
    flex-direction: column;
  }
}
.progress-tabs {
  .md-pills li {
    padding: 0;
  }
  .tab-content {
    padding: 3rem 2rem;
  }
  .md-pills .nav-link span {
    display: inline-block;

    &.number {
      margin-right: 0.25rem;
    }
  }
  .md-pills .nav-link.complete {
    background-color: $primary-color;
    color: #fff;
    height: 3rem;
    .number {
      display: none;
    }
    &:before {
      content: url(images/check-white.svg);
      margin-right: 0.5rem;
    }
  }
  .md-pills .nav-link.active {
    box-shadow: 0 0 0 0;
    background-color: $primary-color;
    position: relative;

    &:after {
      content: "";
      width: 0;
      position: absolute;
      bottom: 0;
      left: 50%;
      margin-left: -0.625rem;
      height: 0;
      border-left: 0.625rem solid transparent;
      border-right: 0.625rem solid transparent;
      border-bottom: 0.625rem solid #fff;
    }
  }
  .nav-link {
    padding: 0.75rem 0;
    border-radius: 0px;
    background: $light-gray;
    color: $gray;
    font-size: 0.875rem;
    line-height: get-line-height(14px, 24px);
  }
  .form-min-height {
    min-height: 20rem;
  }
}

.rgba-grey-light,
.md-pills .nav-link:hover {
  background-color: $light-gray;
}

.rgba-grey-light:after,
.md-pills .nav-link:hover:after {
  background-color: transparent;
}

@media (max-width: 991px) {
  .progress-tabs .tab-content {
    padding: 3rem 0;
  }
}
@media (max-width: 575px) {
  .progress-tabs .tab-content {
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
  }
}
@media (max-width: 768px) {
  .progress-tabs .md-pills .nav-link.complete:before {
    top: 50%;
    transform: translateY(-50%);
    position: absolute;
  }
}
