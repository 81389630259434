.full-width {
  .d-flex {
    flex-wrap: wrap;
  }
  .text-center {
    justify-content: center;
    display: flex;
    flex: 0 0 100%;
  }
  .card {
    flex: 0 0 100%;
  }
}

.card {
	min-width: 19rem;
	border-radius: 0px;
}
