.close {
  color: $gray;
  opacity: 1;
  span {
    display: none;
  }
  &:after {
    content: url(images/close.svg);
  }
}
.circle-wrapper {
cursor: pointer;
  	position: relative;
  display: inline-block;
  border-radius: 50%;
  background-color: #fff;
  width: 6rem;
  height: 6rem;
  text-align: center;
  line-height: 2;
  vertical-align: middle;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;

  &:hover {
background-color: rgba(255,255,255,.7);
  }
  .fa-3x {
    line-height: 2;
  }
  &.dark {
    background-color: $dark-cyan;
&:hover {
    background-color: $sky-blue;

}	
}
  &.med-circle {
    width: 4.5rem;
    height: 4.5rem;

    .fa-lg {
      line-height: 3;
    }
  }
  @media (max-width: 575px) {
  &.med-circle {
    width: 3.5rem;
    height: 3.5rem;
  
.fa-lg {
font-size: 1rem;
line-height: 3.5;
}	
}

  }
}
.fa-lg {
  font-size: 1.5rem;
}
