.selected-symptom .symptom-group {
  box-shadow: inset 0 0 0 2px $ocean-green;
  position: relative;

  h5 {
    color: $dark-cyan;
    font-weight: 700;
  }

  &:after {
    content: url(images/circle-check-green.svg);
    position: absolute;
    position: absolute;
    top: -0.75rem;
    right: 1rem;
    right: 0.5rem;
  }
}

.selected-symptom .short.symptom-group:after {
  right: 0.5rem;
}
.symptom-group {
  box-shadow: inset 0 0 0 1px $blue-gray;
  border-radius: 4px;
  display: inline-block;
  width: 10rem;
  height: 10rem;
  margin: 0.5rem 0.25rem;
  cursor: pointer;

  h5 {
    font-weight: 400;
  }
  &.short {
    height: 4.5rem;
    width: 6.5rem;
    img {
      height: 1.5rem;
      margin-top: 0.5rem;
    }
  }
  img {
    height: 4.5rem;
  }
  img.white-outline {
    display: none;
  }

  &:focus,
  &:hover,
  &:active {
    box-shadow: inset 0 0 0 2px $ocean-green;
  }
}
.open-modal-icons .symptom-group {
  h5 {
    position: relative;
    overflow: hidden;
    &:after {
      content: url(images/arrow-right-white.svg);
      display: block;
      height: 1.5rem;
      line-height: 1.5;
      position: absolute;
      vertical-align: middle;
      margin: 0 auto;
      text-align: center;
      transform: translateX(-24px);
      -webkit-transform: translateX(-24px);
      transition: 0.25s;
      bottom: -24px;
      width: 24px;
    }
  }
  &:focus,
  &:hover,
  &:active {
    box-shadow: inset 0 0 0 0;
    background: $dark-cyan;
    img {
      display: none;
    }
    img.white-outline {
      display: block;
    }
    h5 {
      overflow: visible;
      color: #fff;
    }
    h5:after {
      transform: translateX(80px);
      -webkit-transform: translateX(80px);
      margin-left: -12px;
    }
  }
}

@media (max-width: 575px) {
  .symptom-group {
    width: 100%;
    height: 5.5rem;
    // height: 3.5rem;
    //flex-direction: row !important;
    //    justify-content: left !important;
    margin: 0.5rem 0;
    align-items: center;
    img {
      height: 2.25rem;
      margin: 0 0.75rem;
    }
    h5 {
      margin-top: 0px !important;
      margin-top: 0.25rem !important;
    }
  }
  .symptom-group.short {
    width: 100%;
    height: 3rem;
    img {
      display: none;
    }
    h5 {
      margin: 0px !important;
    }
  }
  .symptom-group-wrapper, .selected-symptom {
    display: flex;
    flex: 0 0 100%;
  }
}

.symptoms-details {
  text-align: left;
}