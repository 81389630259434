.logo-list {
  -webkit-filter: grayscale(100%); /* Safari 6.0 - 9.0 */
  filter: grayscale(100%);
    height: 2rem;
}

img.h72 {
height: 4.5rem;
}
@media (min-width: 992px) {  
.logo-list {
    height: 3rem;
  }
}
