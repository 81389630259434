.nav-link .modal, .tab-pane {
  h4.secondary {
    color: $dark-cyan;
  }
  h4.secondary.text-green {
    color: $ocean-green;
  }
}
.modal-lg.modal-dialog-centered {
  .modal-content {
    background: transparent;
    box-shadow: 0 0 0 0;
  }
  .modal-header {
    background: transparent;
  }
  .modal-body {
    box-shadow: 0 4px 25px 0 rgba($black, 0.3);
  }
  .close:after {
    content: url(images/close-white.svg);
  }
}
@media (min-width: 992px) {
  .modal .med-width.modal-full-height {
    width: 36rem;
  }
}

@media (min-width: 576px) {
  .modal-dialog {
    max-width: 36rem;
    margin: 1.75rem auto;
  }
}

.modal-dialog.modal-lg {
  max-width: 900px;
}

.modal-title {
  font-size: 1.5rem;
  color: $gray;
  line-height: 1.33333333333333;
  font-family: "Comfortaa", cursive;
  font-weight: normal;
  padding: 1.5rem 0 0 0;
}
.symptoms {
  .modal-header {
    background: $primary-color;
  }
  .modal-title {
    color: #fff;
  }
  .close:after {
    content: url(images/close-white.svg);
  }
}
.modal-dialog .modal-content {
  border-radius: 0px;
}
.modal-header {
  padding: 1.5rem 2rem 1rem 2rem;
  background: $pale-gray;
  border-bottom: 0px;
}

.modal-body {
  padding: 3rem 2rem;
color: $dark-cyan;
font-size: 1rem;
}
@media (max-width: 575px) {
  .modal-body {
    padding: 1.5rem;
  }
  .modal-header {
    padding: 1.5rem;
  }
  .modal-title {
    padding: 0;
  }
  .modal-header .close {
    margin: 0;
    padding: 0.5rem 0;
  }
}
.modal-backdrop.show {
  opacity: 0.3;
}
.modal-backdrop {
  background-color: $black;
}

.modal .no-side-margin-modal {
  margin-left: 0px !important;
  margin-right: 0px !important;
}
