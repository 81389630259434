.side-nav {
  margin-left: -33rem;
  -webkit-transform: none !important;
  transform: none !important;
  will-change: margin-left;
  overflow: visible;
  .nav-link {
    color: $dark-cyan;
    line-height: 3;
    padding: 0 1rem;
    padding-left: 1rem;
    padding-right: 1rem;
  }
}
.side-slide-enter-active {
  transform: none !important;
  margin-left: 0px;
  transition: margin-left 300ms, opactiy 300ms;
}

.side-slide-enter {
  transform: none !important;
  -webkit-transform: none !important;
}
.side-slide-exit-done {
}
.side-slide-enter-done {
  margin-left: 0px !important;
  transform: none !important;
  -webkit-transform: none !important;
}

#sidenav-overlay {
  background-color: rgba($black, 0.3);
  z-index: 1038;
}
.side-nav.side-nav-light {
  background-color: #fff;
  color: $black !important;
}

.side-nav {
  width: 33rem;
}

.side-nav.main-nav {
  width: auto;
  width: 16rem;
  background-color: #fff;
  box-shadow: 0 4px 25px 0 rgba($black, 0.3);
  .navbar-nav {
    padding: 1rem 0;
  }
  .navbar-brand {
    display: inline-block;
    padding-top: 0;
    padding-bottom: 0;
    margin-right: 1rem;
    font-size: 1.5rem;
    line-height: 2;
    white-space: nowrap;
    color: $primary-color;
    margin-top: 1rem;
    &:focus,
    &:hover,
    &:active {
      background: transparent;
    }
  }
  a {
    color: $dark-cyan;
    line-height: 3;
    height: auto;
    padding: 0 1rem;
    padding-left: 1rem;
    padding-right: 1rem;
    &:focus,
    &:hover,
    &:active {
      background: $light-gray;
    }
  }

  .nav-separator:before {
    display: none;
  }
  .nav-separator {
    text-align: center;
    background: $light-gray;
    margin: 1rem;
  }
}
