.footer .navbar {
  background: transparent;
  padding: 0;
  box-shadow: 0 0 0 0;

  a.active {
    box-shadow: inset 0 0 0 0;
  }
  .nav-link {
    margin: 0 0.5rem;
  }
  .nav-item:last-of-type .nav-link {
    margin-right: 0px;
    padding-right: 0px;
  }

  @media (max-width: 991px) {
    .navbar-nav.ml-auto {
      margin-right: auto !important;
      margin-left: 0 !important;
      .nav-item:first-of-type a {
        margin-left: 0px;
        padding-left: 0px;
      }
    }
  }
  @media (max-width: 575px) {
    .navbar-nav .nav-link {
      padding: 0.25rem 0;
      padding-left: 0px;
      padding-right: 0px;
      margin-right: 0px;
      margin-left: 0px;
      font-size: 0.875rem;
      line-height: get-line-height(14px, 24px);
    }
  }
}
