@media (max-width: 767.98px) {
  .navbar-expand-md > .container,
  .navbar-expand-md > .container-fluid {
    padding-right: 15px;
    padding-left: 15px;
  }
  .secondary nav.navbar.scrolling-navbar {
    padding-top: 0.25rem;
    padding-bottom: 0.25rem;
  }
}

@media (max-width: 575px) {
  .navbar-expand-md > .container,
  .navbar-expand-md > .container-fluid {
    padding-right: 0;
    padding-left: 0;
  }
}
@media (min-width: 768px) {
  .sidenav-toggle {
    display: none;
  }
}

.navbar.navbar-dark .breadcrumb .nav-item .nav-link:hover,
.navbar.navbar-dark .navbar-nav .nav-item .nav-link:hover {
  color: $light-gray;
}
.sidenav-toggle {
  color: #fff;
}
.secondary a.sidenav-toggle {
  color: $dark-cyan;
}
.navbar.scrolling-navbar {
  box-shadow: 0 0 0 0;
  padding: 1rem;
}
.navbar.scrolling-navbar.top-nav-collapse {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}
.secondary {
  .navbar.scrolling-navbar {
    padding-top: 0rem;
    padding-bottom: 0rem;
  }
  .fixed-top .nav-link {
    padding: 1.5rem 0rem;
  }
}
.navbar a.navbar-brand,
.navbar-brand {
  display: inline-block;
  padding-top: 0;
  padding-bottom: 0;
  margin-right: 1rem;
  font-size: 1.5rem;
  line-height: 2;
  white-space: nowrap;
  font-family: "Comfortaa", cursive;
  color: #fff;
  font-weight: 700;
  &.active {
    box-shadow: inset 0 0 0 0 !important;
  }
}

.navbar.navbar-dark .breadcrumb .nav-item.active > .nav-link,
.navbar.navbar-dark .navbar-nav .nav-item.active > .nav-link {
  background-color: transparent;
}

.navbar.white {
  a {
    color: $gray;
    &.navbar-brand {
      color: $primary-color;
    }
  }
}

.secondary .navbar {
  background: #fff;
  a {
    color: $gray;
    &.navbar-brand {
      color: $primary-color;
    }
    &.active {
      box-shadow: inset 0 -4px 0 0 $primary-color;
    }
  }
}

.navbar.white.navbar-dark .breadcrumb .nav-item .nav-link,
.navbar.navbar-dark.white .navbar-nav .nav-item .nav-link {
  color: $gray;
}

.secondary {
  .navbar.navbar-dark .breadcrumb .nav-item .nav-link,
  .navbar.navbar-dark .navbar-nav .nav-item .nav-link {
    color: $gray;
  }
}

@media (min-width: 768px) {
  .navbar-expand-md .navbar-nav .nav-link {
    margin-right: 1rem;
    margin-left: 1rem;
    padding-right: 0;
    padding-left: 0;
  }
}

.nav-link {
  display: block;
  padding: 0.75rem 1rem;
  cursor: pointer;
}

.nav-separator:before {
  content: "";
  padding-right: 2rem;
  border-left: 1px solid #fff;
  height: 1.5rem;
  display: block;
  float: left;
}

.navbar.white .nav-separator:before,
.secondary .navbar .nav-separator:before {
  border-color: $gray;
}

.secondary .navbar.fixed-top,
.navbar.fixed-top.white {
  box-shadow: 1px 1px 2px 0 rgba(0, 51, 77, 0.2);
}
