.has-error .form-control {
    box-shadow: inset 0 0 0 1px $red;
	background: rgba($red, .1);
}
form {
	margin-bottom: 0;
}
@media (max-width: 575px) {
  #root, .modal-dialog {
    .mobile-inline {
      .btn-primary {
        display: flex;
        flex: 1 1 100%;
        justify-content: center;
      }
      .md-form .form-control.mobile-100 {
      }
    }
  }
  .modal-dialog .mobile-inline {
		
  }
}
#root .input-w-13 {
  width: 13rem;
}
.modal-dialog .input-w-10 {
  width: 10rem;
}
.form-inline label.top-align {
  margin-bottom: 0.5rem;
}
.form-control {
  border-radius: 3rem;
  border: 0px;
  padding: 0.75rem;
  box-shadow: inset 0 0 0 1px $blue-gray;
  height: auto;
  color: $black;

  &.form-control-small {
    width: 10rem;
  }
  @media (max-width: 575px) {
  &.form-control-small {
    width: 7rem;
  }

  }

  &:focus {
    color: $black;
    border: 0px;
    box-shadow: 0 0 0 0;
    box-shadow: inset 0 0 0 3px rgba($sky-blue, 0.5);
  }
}
.form-wrapper {
  background: #fff;
}

#root .form-inline.large,
#root form.large {
  .md-form label {
    top: 1rem;
  }
  .switch {
    height: 3.5rem;
  }
  .switch-selection {
    height: 3.5rem;
    width: 3.5rem;
  }
  .switch-label {
    padding: 1rem;
  }
}
.switch.switch-circle {
  .switch-selection {
    height: 3rem;
    width: 3rem;
  }
  .switch-label {
    padding: 0.75rem 0.875rem;
  }
}
#root .md-form {
  margin-top: 0px;
  margin-bottom: 0px;

  input[type="text"], input[type="number"] {
    background-color: $light-gray;
    border: 0px;
    margin-bottom: 0;
    padding-left: 1.5rem;
    padding-right: 1.5rem;

    &:focus:not([readonly]) {
      box-shadow: 0 0 0 0;
    }
    &.input-white {
      background-color: rgba(255, 255, 255, 0.9);
      border: 0px;
      &:focus:not([readonly]) {
        background-color: rgba(255, 255, 255, 1);
      }
    }
    &:focus {
      color: $black;
    }
  }

  .form-control.input-large {
    font-size: 1.125rem;
    box-shadow: inset 0 0 0 0;
    padding: 1rem 1.5rem;
    box-sizing: border-box;
    line-height: 1.3333333333333;
  }
  .input-rounded {
    border-radius: 3rem;
  }

  label {
    position: absolute;
    top: 0.75rem;
    margin-bottom: 0;
    left: 1.5rem;
    transition: 0.2s ease-out;
    cursor: text;
    color: $gray;
  }
  label.active {
    transform: translate(-1.5rem, -2.5rem);
    color: #fff;
    font-size: 0.75rem;
    line-height: 2;
  }
  .form-control {
    padding: 0.75rem;
    box-shadow: inset 0 0 0 1px $blue-gray;
  }
}

#root .white-bg .md-form label.active {
  color: $gray;
}
.form-inline.large label,
form.large label {
  font-size: 1.125rem;
  line-height: 1.3333333333333;
}

.switch {
  position: relative;
  height: 3rem;
  width: auto;
  background: rgba(255, 255, 255, 0.3);
  border-radius: 3rem;

  &.green {
    background-color: #fff !important;
    box-shadow: inset 0 0 0 1px $blue-gray;
    .switch-selection {
      background-color: $ocean-green;
    }
    .switch-label {
      color: $gray;
    }
    .switch-input:checked + .switch-label {
      color: #fff;
    }
  }
}

.switch-label {
  position: relative;
  z-index: 2;
  float: left;
  width: 50%;
  padding: 0.75rem 1.25rem;
  line-height: 1.5;
  color: #fff;
  text-align: center;
  cursor: pointer;
  display: flex;
}

.switch-input {
  display: none;
}
.switch-input:checked + .switch-label {
  color: $black;
  -webkit-transition: 0.15s ease-out;
  -moz-transition: 0.15s ease-out;
  -ms-transition: 0.15s ease-out;
  -o-transition: 0.15s ease-out;
  transition: 0.15s ease-out;
}
.switch-input:checked + .switch-label-on ~ .switch-selection {
  left: 50%;
}

.switch-selection {
  position: absolute;
  z-index: 1;
  top: 0;
  left: 0;
  display: block;
  width: 50%;
  height: 3rem;
  border-radius: 3rem;
  background-color: #fff;
  -webkit-transition: left 0.15s ease-out;
  -moz-transition: left 0.15s ease-out;
  -ms-transition: left 0.15s ease-out;
  -o-transition: left 0.15s ease-out;
  transition: left 0.15s ease-out;
}

.dosing-calculator-label {
  color: #7A8E99
}
