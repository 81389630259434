$black: #242E33;
$blush: #F5917A;
$off-blue: #5994B3;
$dark-cyan: #00334D;
$ocean-green: #71BD9F;
$sea-foam-green: #B4E0CE;
$sky-blue: #8AC5E6;
$fresh-air: #C4E4F5;
$slate: #667780;
$gray: #7A8E99;
$medium-gray: #98A9B3;
$pale-gray: #F2F6F7;
$blue-gray: #B4C6D4;
$light-gray: #DAE1E6;
$violet: #B593C2;
$red: #B84242;
$primary-color: $blush;

.text-red, .red-text {
color: $red !important;
}
.text-primary {
	color: $primary-color !important;
}
.text-green {
	color: $ocean-green;
}
