ul.large {
	font-size: 1.25rem;
	line-height: get-line-height(20px, 32px);
	padding: 0px;
    list-style-position: inside;
    list-style-type: none;

	li:before {
		content: url(images/bullet.svg);
		padding-right: .75rem;
	}
	li {
	padding: .25rem 0;
	}
}
