h2 {
  font-size: 2.5rem;
  line-height: get-line-height(40px, 56px);
  font-family: "Comfortaa", cursive;
  font-weight: normal;
}

@media (max-width: 575px) {
  h2 {
    font-size: 2rem;
    line-height: get-line-height(32px, 48px);
  }
}

h2.underline {
  font-size: 1.5rem;
  line-height: get-line-height(24px, 28px);
  font-family: "Comfortaa", cursive;
  font-weight: normal;

  &:after {
    content: "";
    display: block;
    border-bottom: 4px solid $off-blue;
    width: 4rem;
    padding-top: 1rem;
  }
}

h2.light {
  color: $gray;
}

h4 {
  font-size: 1.25rem;
  line-height: get-line-height(20px, 32px);
  font-weight: normal;

  &.secondary {
    font-size: 1.125rem;
    font-weight: 700;
    line-height: get-line-height(18px, 24px);
    line-height: 1.33333333;
  }
}

@media (max-width: 575px) {
h4 {
  font-size: 1.125rem;
  line-height: get-line-height(18px, 28px);

}
}

.card .card-body h4 {
  font-weight: 700;
}

h3 {
  font-size: 1.5rem;
  line-height: 1;
  color: $gray;
  font-weight: 700;
}
h3:after {
  content: "";
  padding-top: 1rem;
  border-bottom: 2px solid $primary-color;
  width: 6.25rem;
  display: block;
  margin: 0 auto;
}

h3.or {
  font-size: 1.125rem;
  line-height: get-line-height(18px, 18px);
  display: inline-block;
  margin: 1rem 0;
}
h3.or:after {
  border-bottom: 4px solid $primary-color;
  width: auto;
  padding-top: 0.5rem;
}

h5 {
  color: $gray;
  font-size: 0.875rem;
  line-height: get-line-height(14px, 24px);
  font-weight: 700;
}
