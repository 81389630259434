.tooltip-info {
    border: 0px;
    padding: 0 .5rem;
	color: $off-blue;
	background: transparent;
}

.tooltip {
	text-align: left;
	font-family: inherit;
}

.tooltip-inner {
    max-width: 20rem;
    padding: .5rem;
    color: #fff;
    text-align: left;
    background-color: $dark-cyan;
    border-radius: 2px;
}
.tooltip.show {
    opacity: .95;
}
.bs-tooltip-auto[x-placement^=right] .arrow::before, .bs-tooltip-right .arrow::before {
    right: 0;
    border-width: .4rem .4rem .4rem 0;
    border-right-color: $dark-cyan;
}
.bs-tooltip-auto[x-placement^=bottom] .arrow::before, .bs-tooltip-bottom .arrow::before {    
border-bottom-color: $dark-cyan;
}
